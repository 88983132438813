// Import JS used on multiple pages
import ToastFlash from 'src/javascripts/components/alerts/ToastFlash';
import FocusSearchForm from 'src/javascripts/components/forms/FocusSearchForm';
import ScrollToTag from 'src/javascripts/components/nav/ScrollToTag';
import Collapsible from 'src/javascripts/components/utilities/Collapsible';
import TooltipInit from 'src/javascripts/components/utilities/TooltipInit';
import NavLanding from 'src/javascripts/components/nav/NavLanding';
import NavbarNotifications from 'src/javascripts/components/notifications/NavbarNotifications';
import MasterSearchTypeahead from 'src/javascripts/components/typeaheads/MasterSearchTypeahead';

// Pages
import Users from 'src/javascripts/pages/Users';
import Auth from 'src/javascripts/pages/Auth';
import Data from 'src/javascripts/pages/Data';
import SettingsUsersSecurity from 'src/javascripts/pages/users/SettingsUsersSecurity';

// Comments
import CommentsEdit from 'src/javascripts/pages/comments/CommentsEdit';
import CommentsNew from 'src/javascripts/pages/comments/CommentsNew';

// Companies
import CompaniesEdit from 'src/javascripts/pages/companies/CompaniesEdit';
import CompaniesNew from 'src/javascripts/pages/companies/CompaniesNew';
import CompaniesIndex from 'src/javascripts/pages/companies/CompaniesIndex';

import CompaniesMetricScoresEdit from 'src/javascripts/pages/companies/metric_scores/CompaniesMetricScoresEdit';
import CompaniesMetricScoresIndex from 'src/javascripts/pages/companies/metric_scores/CompaniesMetricScoresIndex';
import CompaniesMetricScoresNew from 'src/javascripts/pages/companies/metric_scores/CompaniesMetricScoresNew';
import CompaniesUnmappedMetricScoresIndex from 'src/javascripts/pages/companies/unmapped_metric_scores/CompaniesUnmappedMetricScoresIndex';

import CompaniesScreensIndex from 'src/javascripts/pages/companies/screens/CompaniesScreensIndex';
import CompaniesControversiesIndex from 'src/javascripts/pages/companies/controversies/CompaniesControversiesIndex';
import CompaniesControversiesNew from 'src/javascripts/pages/companies/controversies/CompaniesControversiesNew';
import CompaniesControversiesEdit from 'src/javascripts/pages/companies/controversies/CompaniesControversiesEdit';

import CompaniesSdgActivitiesNew from 'src/javascripts/pages/companies/sdg_activities/CompaniesSdgActivitiesNew';
import CompaniesSdgActivitiesIndex from 'src/javascripts/pages/companies/sdg_activities/CompaniesSdgActivitiesIndex';

import CompaniesLocationsNew from 'src/javascripts/pages/companies/locations/CompaniesLocationsNew';
import CompaniesLocationsIndex from 'src/javascripts/pages/companies/locations/CompaniesLocationsIndex';

import CompaniesFilesIndex from 'src/javascripts/pages/companies/files/CompaniesFilesIndex';
import CompaniesFilesImportsNew from 'src/javascripts/pages/companies/files/imports/CompaniesFilesImportsNew';

import CompaniesReviewsIndex from 'src/javascripts/pages/companies/reviews/CompaniesReviewsIndex';
import CompaniesMappingsNew from 'src/javascripts/pages/companies/mappings/CompaniesMappingsNew';

// Metrics
import MetricsIndex from 'src/javascripts/pages/metrics/MetricsIndex';
import MetricScoresIndex from 'src/javascripts/pages/metric_scores/MetricScoresIndex';

// Screens
import ScreensIndex from 'src/javascripts/pages/screens/ScreensIndex';

// Controversies
import ControversiesIndex from 'src/javascripts/pages/controversies/ControversiesIndex';

// Funds
import FundsEdit from 'src/javascripts/pages/funds/FundsEdit';
import FundsNew from 'src/javascripts/pages/funds/FundsNew';
import FundsIndex from 'src/javascripts/pages/funds/FundsIndex';

// Fund weights
import FundsWeightsEdit from 'src/javascripts/pages/funds/weights/FundsWeightsEdit';
import FundsWeightsNew from 'src/javascripts/pages/funds/weights/FundsWeightsNew';
import FundsWeightsIndex from 'src/javascripts/pages/funds/weights/FundsWeightsIndex';
import FundsFilesImportsNew from 'src/javascripts/pages/funds/files/imports/FundsFilesImportsNew';
import FundsFilesImportsMap from 'src/javascripts/pages/funds/files/imports/FundsFilesImportsMap';

// Dashboard
import DashboardsIndex from 'src/javascripts/pages/dashboards/DashboardsIndex';

import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, logoTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';

// Create a map where keys share same names as classes
const pageClasses = {
  Auth, SettingsUsersSecurity, CompaniesEdit, CompaniesIndex, CompaniesMetricScoresEdit, 
  CompaniesFilesImportsNew, CompaniesMetricScoresIndex, CompaniesControversiesIndex, 
  CompaniesFilesIndex, CompaniesScreensIndex, MetricScoresIndex, ScreensIndex, CompaniesReviewsIndex,
  DashboardsIndex, CompaniesNew, CompaniesControversiesNew, CompaniesControversiesEdit,
  CompaniesSdgActivitiesIndex, CompaniesSdgActivitiesNew, CompaniesLocationsIndex,
  CompaniesLocationsNew, ControversiesIndex, CompaniesMetricScoresNew, MetricsIndex,
  CompaniesMappingsNew, CompaniesUnmappedMetricScoresIndex, FundsEdit, FundsNew, 
  FundsIndex, FundsWeightsEdit, FundsWeightsNew, FundsWeightsIndex, FundsFilesImportsNew,
  FundsFilesImportsMap, CommentsEdit, CommentsNew
}

// Create a map for module-wide classes
const moduleClasses = {Auth, Users}

// Set vars for listening for reloading page, maintaining scroll position if on same page
// https://stackoverflow.com/questions/39679154/how-to-persist-scrolls-with-turbolinks
const scrollPositions = {}
let page = $('body').data('page');
let cModule = $('body').data('controller-module');
scrollPositions[page] = null
scrollPositions[cModule] = null

// Init page
const Init = function() {

  // Add lastpass and chrome ignore indicators
  if ($('body').attr('data-signed-in') === 'true') {
    $('input').attr('data-lp-ignore', 'true');
  }
  $('input').attr('autocomplete', 'off');

  // Show flash toast, if present on page
  if ($('.flash').length !== 0) { ToastFlash(); }

  // Add border to navbar landing, if on a landing page
  if ($('.nav-landing').length !== 0) { NavLanding(); }

  // Top navbar if signed in
  if ($('#navTop').length !== 0) {NavbarNotifications();}

  // Toggle focus for typeahead on click of search button
  // Used in multiple nav bars
  if ($('.focus-search-form').length !== 0) {
    $('.focus-search-form').click(function() { FocusSearchForm($(this)); });
  }

  // Company search
  if ($('#companies_top_search').length !== 0) {
    filterTypeahead('#companies_top_search', 'companies', '/companies/search?', 172, 'logo');
    $('#companies_top_search').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
      ev.preventDefault();
      $('.tooltip').tooltip('hide');
      Turbolinks.visit("/companies/" + suggestion.value);
    });
  }

  // Toggle full-window view for cards
  if ($('.toggle-full-view').length !== 0) {
    $('.toggle-full-view').click(function() {
      let $card = $(this).closest('.card')
      let $chart = $card.find('.chart').data('c3-chart');
      $card.toggleClass('full-view');
      if ( typeof $chart !== 'undefined' ) {
        setTimeout(function() {
          $chart.flush();
        }, 100);
      }
    });
  }

  // Multi-search causes, companies, funds
  if ( $('#master_search_typeahead') ) {
    let masterSearch = new MasterSearchTypeahead('master_search_typeahead');
    $.when( masterSearch.render() );
  }

  // Scroll to anchor on click
  if ($('.scroll-to-anchor').length !== 0) {
    $('.scroll-to-anchor').click(function(e) {
      e.preventDefault();
      let tag = $(this).attr('data-tag');
      return ScrollToTag(tag);
    });
  }

  // Set collapsible
  if ($('.collapse-item').length !== 0) {
    Collapsible();
  }

  // Init tooltips
  TooltipInit();

  // Remove toasts once they're hidden, so they don't show up on page change
  $('.toast').on('hidden.bs.toast', function () {
    $(this).remove();
  });

  // Auto-show modals
  if ($('.modal.auto-show').length !== 0) {

    // Show modal if present on page (if user signed in)
    $('.modal.auto-show').on('shown.bs.modal', function() {

      // get the top of the section
      let p = $(this).find('.modal-body').attr("data-scroll-p");

      // Scroll to section if p present
      if (typeof p !== 'undefined') { $(this).find('.modal-body').scrollTop(p); }

    }).modal({backdrop: 'static', keyboard: false});

  }

  // Set page, parent and should scroll attrs from body
  let page = $('body').data('page');
  let module = $('body').data('controller-module');
  let shouldScroll = $('body').data('should-scroll');

  // Load at scroll position, if body indicates we should
  if ( (typeof shouldScroll !== 'undefined') && (scrollPositions[module]) ) {
    window.scrollTo(0, scrollPositions[module]);
  } else if ( (typeof shouldScroll !== 'undefined') && (scrollPositions[page]) ) {
    window.scrollTo(0, scrollPositions[page]);
  }

	// Execute page class, if exists
	if (typeof pageClasses[page] !== 'undefined') {
		new pageClasses[page]().render();
	}

  // Execute module class, if exists
  if (typeof moduleClasses[module] !== 'undefined') {
    new moduleClasses[module]().render();
  }

  // Execute data module for every page
  if ($('body').data('controller-module') !== "Auth") {
    new Data().render();
  }

}


// Call init function on page load
// Remove typeaheads, charts and datatables before cache
export default $(document).on("turbolinks:load", function() { 

	Init(); 

}).on("turbolinks:before-visit", function() {

  // Add listeners for reloading page, maintaining scroll position if on same page
  // https://stackoverflow.com/questions/39679154/how-to-persist-scrolls-with-turbolinks
  let page = $('body').data('page');
  let cModule = $('body').data('controller-module');
  scrollPositions[page] = window.scrollY;
  scrollPositions[cModule] = window.scrollY;

}).on("turbolinks:before-cache", function() {

  // Collapse master search if present
  if ($('#master_search_typeahead').length !== 0) {
    $('#navbarSearchSm').removeClass('show');
    $('#master_search_typeahead').val('');
  }

  // Destroy typeaheads to avoid dup rendering on back button
  $('.typeahead').typeahead('destroy');

  // Empty legends from below charts
  if ($('.legend').length !== 0) { $('.legend').empty(); }

  // Remove google maps from BOM (window object)
  if (window.google !== undefined && window.google.maps !== undefined) {
    window.google.maps = undefined;
  }

  // Destroy datatables
  $('.dataTable.server-side tbody').remove(); // Avoid flickering/jumping on back button for tables that get data from server
  $('.dataTable').DataTable().destroy();

  // Remove modal backdrop; if don't remove before cache it appears twice on back button
  $('.modal-backdrop').remove();

  // Hide modals
  $('.modal').modal('hide');

  // Hide tooltips if not on mobile/touch
  let isTouchDevice = function() { 
    return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
  }
  if (isTouchDevice() === false) {
    $('[data-toggle="tooltip"]').tooltip('hide');
  }


});