import NumberDisplay from 'src/javascripts/components/utilities/NumberDisplay';
import {filterTypeahead} from 'src/javascripts/components/typeaheads/TypeaheadBloodhounds';
import {noResultsTemplate, emptyTemplate, logoTemplate, genericDisplayTemplate} from 'src/javascripts/components/typeaheads/TypeaheadTemplates';
import 'src/javascripts/vendor/typeahead.jquery';
import 'src/javascripts/vendor/bloodhound';
import Spinner from 'src/javascripts/components/utilities/Spinner';

// Must define Bloodhound explicitly here
// https://stackoverflow.com/questions/30750916/how-to-reference-typeahead-and-bloodhound-when-loading-npm-typeahead-js
const Bloodhound = require('src/javascripts/vendor/bloodhound');

export default function() {

  // Number display
  NumberDisplay();

  // Auto focus on typeahead when open a dropdown
  $('.dropdown').on('shown.bs.dropdown', function () {
    let input = $(this).find('input');
    if (typeof input !== 'undefined') {
      input.focus();
    }
  })

  // Start with sector
  filterTypeahead('.sector-filter', 'sectors', '/companies/attrs/search?attr=sector&', 40, 'generic');


  // Set industry using sector
  let setUpIndustryTypeahead = function() {
    let industryUrl = '/companies/attrs/search?attr=industry&sector=';
    let industryBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: industryUrl,
        cache: false,
        replace: function () {
        	var q = '/companies/attrs/search?attr=industry&sector=';
          if ($('#sectors_typeahead').val()) {
          	q += encodeURIComponent($('#sectors_typeahead').val());
          }
        	return q;
        }
    	}
    });
    industryBloodhound.initialize();
    $('.industry-filter').typeahead({
      hint: true,
      highlight: true,
      minLength: 0,
    }, {
      name: 'industries',
      display: 'name',
      limit: 115,
      source: industryBloodhound,
      templates: {
        suggestion(el) {
          if (el.name === null) {
            return noResultsTemplate();
          } else {
            return genericDisplayTemplate(el.value);
          }
        }
      },
    });
  }
  setUpIndustryTypeahead();


  // Set peer group using sector
  let setUpPeerGroupTypeahead = function() {
    let peerGroupUrl = '/companies/attrs/search?attr=peer_group&industry=';
    let peerGroupBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: peerGroupUrl,
        cache: false,
        replace: function () {
        	var q = '/companies/attrs/search?attr=peer_group&industry=';
          if ($('#industries_typeahead').val()) {
          	q += encodeURIComponent($('#industries_typeahead').val());
          }
        	return q;
        }
    	}
    });
    peerGroupBloodhound.initialize();
    $('.peer-group-filter').typeahead({
      hint: true,
      highlight: true,
      minLength: 0,
    }, {
      name: 'peer_groups',
      display: 'name',
      limit: 115,
      source: peerGroupBloodhound,
      templates: {
        suggestion(el) {
          if (el.name === null) {
            return noResultsTemplate();
          } else {
            return genericDisplayTemplate(el.value);
          }
        }
      },
    });
  }
  setUpPeerGroupTypeahead();


  // Set SASB sector using sector
  let setUpSasbTypeahead = function() {
    let sasbUrl = '/companies/sasb_industries/search?peer_group=';
    let sasbBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        url: sasbUrl,
        cache: false,
        replace: function () {
        	var q = '/companies/sasb_industries/search?peer_group=';
          if ($('#peer_groups_typeahead').val()) {
          	q += encodeURIComponent($('#peer_groups_typeahead').val());
          }
        	return q;
        }
    	}
    });
    sasbBloodhound.initialize();
    $('.sasb-industry-filter').typeahead({
      hint: true,
      highlight: true,
      minLength: 0,
    }, {
      name: 'sasb_industries',
      display: 'industry',
      limit: 314,
      source: sasbBloodhound,
      templates: {
        suggestion(el) {
          if (el.industry === null) {
            return noResultsTemplate();
          } else {
            return genericDisplayTemplate(el.industry);
          }
        }
      },
    });
  }
  setUpSasbTypeahead();


  // On blur
  $('.filter-companies-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
    ev.preventDefault();
    $('.tooltip').tooltip('hide');
    let hiddenField = $(this).closest('.form-group').find('input[type=\'hidden\']');
    hiddenField.val(suggestion.value);
    if (typeof suggestion.industry !== 'undefined') {
      $(this).typeahead('val', suggestion.industry);
    } else {
      $(this).typeahead('val', suggestion.value);
    }
    $(this).blur();
    if ($(this).hasClass('sector-filter')) {
    	$('.no-sector').addClass('d-none');
    	$('#industries_typeahead').typeahead('destroy');
      $('#industries_typeahead').val('');
      $('#industries_typeahead').removeClass('d-none');
      setUpIndustryTypeahead();
      $('#peer_groups_typeahead').typeahead('destroy');
      $('#peer_groups_typeahead').val('');
      setUpPeerGroupTypeahead();
      $('#sasb_industries_typeahead').typeahead('destroy');
      $('#sasb_industries_typeahead').val('');
      setUpSasbTypeahead();
    } else if ($(this).hasClass('industry-filter')) {
    	$('.no-industry').addClass('d-none');
      $('#peer_groups_typeahead').typeahead('destroy');
      $('#peer_groups_typeahead').val('');
      $('#peer_groups_typeahead').removeClass('d-none');
      setUpPeerGroupTypeahead();
      $('#sasb_industries_typeahead').typeahead('destroy');
      $('#sasb_industries_typeahead').val('');
      setUpSasbTypeahead();
    } else if ($(this).hasClass('peer-group-filter')) {
    	$('.no-peer-group').addClass('d-none');
      $('#sasb_industries_typeahead').typeahead('destroy');
      $('#sasb_industries_typeahead').val('');
      $('#sasb_industries_typeahead').removeClass('d-none');
      setUpSasbTypeahead();
    }
  });


  // EODH Codes //
  // Start with eodh exchange
  filterTypeahead('.eodh-exchange-typeahead', 'eodh_exchanges_typeahead', '/eodh/search_exchanges?', 223, 'exchange');

  // Set eodh code using exchange
  let setUpEodhCodeTypeahead = function(el) {
    let et = el.closest('.share-class-set').find('input[type=\'hidden\']').val();
    let codesUrl = '/eodh/search_codes?exchange=' + et + '&query=';
    let codesBloodhound = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: {
        cache: false,
        url: codesUrl + '%QUERY',
        wildcard: "%QUERY"
      }
    });
    codesBloodhound.initialize();
    $(ec).typeahead({
      hint: true,
      highlight: true,
      minLength: 0,
    }, {
      name: 'codes',
      display: 'name_display',
      limit: 352,
      source: codesBloodhound,
      templates: {
        suggestion(el) {
          if (el.name === null) {
            return noResultsTemplate();
          } else {
            return genericDisplayTemplate(el.name_display);
          }
        }
      },
    });
  }
  let ec = $('.eodh-code-typeahead');
  setUpEodhCodeTypeahead(ec);

  // On select exchange
  $('.eodh-exchange-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
    ev.preventDefault();
    $('.tooltip').tooltip('hide');
    let hiddenField = $(this).closest('.form-group').find('input[type=\'hidden\']');
    hiddenField.val(suggestion.code);
    $(this).typeahead('val', suggestion.code);
    $(this).blur();
    let sc = $(this).closest('.share-class-set');
    let ec = sc.find('.eodh-code-typeahead');
    sc.find('.no-exchange').addClass('d-none');
    ec.typeahead('destroy');
    ec.val('');
    ec.removeClass('d-none');
    setUpEodhCodeTypeahead(ec);
  });

  // On select exchange
  $('.eodh-code-typeahead').bind('typeahead:beforeselect typeahead:autocomplete', function(ev, suggestion) {
    ev.preventDefault();
    $('.tooltip').tooltip('hide');
    let hiddenField = $(this).closest('.form-group').find('input[type=\'hidden\']');
    hiddenField.val(suggestion.code);
    $(this).typeahead('val', suggestion.code);
    $(this).blur();
  });


  // Add share class
  $('.add-share-class').click(function() {
    $('#add_share_class').val('true');
    $('.submit-company-form').click();
  })

  // BBG IDs
  $('.bbg-global-company-id').blur(function() {
    let val = $(this).val();
    if (val.length === 0) {
      $(this).closest('.form-group').find('.hidden-bbg-destroy').val("true");
    } else {
      $(this).closest('.form-group').find('.hidden-bbg-destroy').val("false");      
    }
  })


}